import firebase from "../firebase";

const auth = firebase.auth();

const AuthAPI = {
    login: async (email, password) => {
        try {
            return await auth.signInWithEmailAndPassword(email, password);
        } catch (error) {
            return false;
        }
    },

    logout: async () => {
        try {
            return await auth.signOut();
        } catch (error) {
            return false;
        }
    },
};

export default AuthAPI;
