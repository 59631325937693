import firebase from "../firebase";

const db = firebase.firestore();

const firestoreAPI = {
    getAllItems: async () => {
        const snapshot = await db.collection("User").get();
        return snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
    },

    addItem: async (item) => {
        await db.collection("User").add(item);
    },

    updateItem: async (id, updatedItem) => {
        await db.collection("User").doc(id).set(updatedItem, { merge: true });
    },

    deleteItem: async (id) => {
        await db.collection("User").doc(id).delete();
    },
    deleteAssociatedItems: async (id, email) => {
        const querySnapshotEntryDetails = await db
            .collection("Entry Details")
            .where("email_Id", "==", email)
            .get();

        querySnapshotEntryDetails.forEach(async (doc) => {
            try {
                await db.collection("Entry Details").doc(doc.id).delete();
            } catch (error) {
                console.error("Error deleting document: ", error);
            }
        });

        const querySnapshotCategory = await db
            .collection("Category")
            .where("email_Id", "==", email)
            .get();

        querySnapshotCategory.forEach(async (doc) => {
            try {
                await db.collection("Category").doc(doc.id).delete();
            } catch (error) {
                console.error("Error deleting document: ", error);
            }
        });

        const querySnapshotBusiness = await db
            .collection("Business")
            .where("email_Id", "==", email)
            .get();

        querySnapshotBusiness.forEach(async (doc) => {
            try {
                await db.collection("Business").doc(doc.id).delete();
            } catch (error) {
                console.error("Error deleting document: ", error);
            }
        });

        await db.collection("User").doc(id).delete();
    },
};

export default firestoreAPI;
