// Import necessary modules
import React, { useState, useEffect } from "react";
import userService from "../../services/users";
import { Link } from "react-router-dom";

// Component for displaying items
const UserList = () => {
    const [items, setItems] = useState([]);
    const [loader, setLoader] = useState(false);

    const fetchData = async () => {
        setLoader(true);
        const data = await userService.getAllItems();
        setLoader(false);
        setItems(data);
    };

    const handleDelete = async (e, id, email) => {
        e.preventDefault();

        setLoader(true);

        await userService.deleteAssociatedItems(id, email);

        fetchData();
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <div>
            <div class={`${loader && "loading"}`}></div>
            <h3>Users List</h3>
            <div className="row justify-content-evenly mt-4">
                <div className="col-12">
                    <table className="table table-striped">
                        <thead className="table-dark">
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Name</th>
                                <th scope="col">Phone</th>
                                <th scope="col">Email</th>
                                <th scope="col">Address</th>
                                <th scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {items.map((item, i) => (
                                <tr key={item.id}>
                                    <th scope="row">{i + 1}</th>
                                    <td>{item.name}</td>
                                    <td>{item.mob}</td>
                                    <td>{item.email}</td>
                                    <td>{item.address}</td>
                                    <td>
                                        <Link
                                            to={"/users/edit"}
                                            state={item}
                                            className="btn btn-sm btn-secondary me-2"
                                        >
                                            Edit
                                        </Link>
                                        <button
                                            type="button"
                                            className="btn btn-sm btn-danger"
                                            onClick={(e) =>
                                                handleDelete(
                                                    e,
                                                    item.id,
                                                    item.email
                                                )
                                            }
                                        >
                                            Delete
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default UserList;
