import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/auth";

const firebaseConfig = {
    apiKey: "AIzaSyCG0LLlSmL5MrVa_LzlbrNyblv9GJIlTvI",
    authDomain: "cashbook-mobile-app.firebaseapp.com",
    databaseURL: "https://cashbook-mobile-app-default-rtdb.firebaseio.com",
    projectId: "cashbook-mobile-app",
    storageBucket: "cashbook-mobile-app.appspot.com",
    messagingSenderId: "939276000087",
    appId: "1:939276000087:web:4fa4190f4e14f17a38bc12",
    measurementId: "G-JL5LN42Z6D",
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export default firebase;
