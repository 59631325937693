const PrivacyPolicy = () => {
    return (
        <div className="container mt-70">
            <div className="row justify-content-center py-4">
                <p>
                    This Privacy Policy describes how Digital Cashbook App
                    collects, uses, and shares information about you when you
                    use our mobile application available on the Google Play
                    Store / Apple App Store. By using the App, you consent to
                    the collection, use, and sharing of your information as
                    described in this Privacy Policy.
                </p>
                <p>
                    <ol type="1">
                        <li>
                            <strong>Information We Collect</strong>
                            <ol type="a">
                                <li>
                                    <strong>Personal Information:</strong> We
                                    collect personal information such as your
                                    Name, Email address, Phone number when
                                    creating an account in mobile app.
                                </li>
                                <li>
                                    <strong>Business Information:</strong> We
                                    will ask you to add the multiple businesses
                                    in app and manage day-to day IN/OUT entries
                                    corresponding to each business.
                                </li>
                            </ol>
                        </li>
                        <br />
                        <li>
                            <strong>How We Use Your Information</strong>: We may
                            use the information we collect for the following
                            purposes:
                            <ol type="a">
                                <li>
                                    To provide, maintain, and improve the App
                                    and services;
                                </li>
                                <li>
                                    To personalize your experience and deliver
                                    content tailored to your interests;
                                </li>
                                <li>
                                    To communicate with you, respond to your
                                    inquiries, and provide customer support;
                                </li>
                                <li>To send you important notices, updates;</li>
                                <li>
                                    To analyze usage trends and improve the
                                    App's functionality and performance;
                                </li>
                                <li>For any other purpose with your consent</li>
                            </ol>
                        </li>
                        <br />
                        <li>
                            <strong>Security</strong>: We take reasonable
                            measures to protect the security of your
                            information, but please be aware that no method of
                            transmission over the internet or electronic storage
                            is 100% secure. Therefore, we cannot guarantee the
                            absolute security of your information.
                        </li>
                        <br />
                        <li>
                            <strong>Children's Privacy</strong>: The App is not
                            intended for children under the age of 13, and we do
                            not knowingly collect personal information from
                            children under the age of 13. If you believe we have
                            collected information from a child under the age of
                            13, please contact us immediately.
                        </li>
                        <br />
                        <li>
                            <strong>Changes to this Privacy Policy</strong>: We
                            may update this Privacy Policy from time to time,
                            and any changes will be effective when we post the
                            revised Privacy Policy. We encourage you to review
                            this Privacy Policy periodically for updates.
                        </li>
                        <br />
                        <li>
                            <strong>Contact Us</strong>: If you have any
                            questions or concerns about this Privacy Policy or
                            our privacy practices, please contact us at{" "}
                            <a href="mailto:appandroid@stpl.biz">
                                appandroid@stpl.biz
                            </a>
                            .<br />
                            By using the Digital Cashbook App, you acknowledge
                            that you have read and understood this Privacy
                            Policy and agree to be bound by its terms.
                        </li>
                    </ol>
                </p>
            </div>
        </div>
    );
};

export default PrivacyPolicy;
