import { useEffect, useState } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import Backend from "./layout/Backend";
import Frontend from "./layout/Frontend";
import UserList from "../modules/user/UserList";
import UserEdit from "../modules/user/UserEdit";
import Login from "../modules/login/Login";
import { Context } from "./Context";
import firebase from "../firebase";
import PrivacyPolicy from "../modules/pages/PrivacyPolicy";

const auth = firebase.auth();

const Routing = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [context, setContext] = useState();

    useEffect(() => {
        if (location.pathname !== "/privacy-policy") {
            if (!context) {
                const unsubscribe = auth.onAuthStateChanged((user) => {
                    if (user?.uid) {
                        setContext(user.uid);

                        navigate("/users");
                    } else {
                        navigate("/login");
                    }
                });

                return unsubscribe;
            }
        }
    }, []);

    return (
        <Context.Provider value={[context, setContext]}>
            <Routes>
                <Route path="/" element={<Frontend />}>
                    <Route path="" element={<Login />} />
                    <Route path="login" element={<Login />} />
                    <Route path="privacy-policy" element={<PrivacyPolicy />} />
                </Route>
                {context ? (
                    <Route path="/users" element={<Backend />}>
                        <Route path="" element={<UserList />} />
                        <Route path="edit" element={<UserEdit />} />
                    </Route>
                ) : (
                    <Route to="/login" replace={true} />
                )}
            </Routes>
        </Context.Provider>
    );
};

export default Routing;
