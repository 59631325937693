import React, { useContext, useState } from "react";
import AuthAPI from "../../services/auth";
import { useNavigate } from "react-router-dom";
import { Context } from "../../components/Context";

const Login = () => {
    const navigate = useNavigate();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const [context, setContext] = useContext(Context);
    const [tinySpinner, setTinySpinner] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();

        setTinySpinner(true);
        try {
            let user = await AuthAPI.login(email, password);

            setTinySpinner(false);

            if (user) {
                setContext(user.user.uid);
                navigate("/users");
            } else {
                setError("Login credential is incorrect.");
            }
        } catch (error) {
            setTinySpinner(false);
            setError(error.message);
        }
    };

    return (
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-4 mt-200 py-4 px-5 bg-gray rounded">
                    <h3 className="text-center">Login</h3>
                    <p className="text-center text-danger">{error}</p>
                    <form
                        className="row g-3 needs-validation"
                        noValidate
                        onSubmit={handleSubmit}
                    >
                        <div className="col-12">
                            <label
                                htmlFor="validationCustom01"
                                className="form-label"
                            >
                                Email
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                id="validationCustom01"
                                defaultValue={email}
                                required
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            <div className="valid-feedback">Looks good!</div>
                        </div>
                        <div className="col-12">
                            <label
                                htmlFor="validationCustom01"
                                className="form-label"
                            >
                                Password
                            </label>
                            <input
                                type="password"
                                className="form-control"
                                id="validationCustom01"
                                defaultValue={password}
                                required
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            <div className="valid-feedback">Looks good!</div>
                        </div>

                        <div className="col-12 text-center">
                            <button
                                className={`btn btn-primary ${
                                    tinySpinner && "disabled"
                                }`}
                                type="submit"
                            >
                                {tinySpinner && (
                                    <span
                                        class="spinner-border spinner-border-sm me-2"
                                        role="status"
                                        aria-hidden="true"
                                    ></span>
                                )}
                                Login
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Login;
