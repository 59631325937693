import { useContext } from "react";
import AuthAPI from "../services/auth";
import { Context } from "./Context";
import { useNavigate } from "react-router-dom";

const Header = (props) => {
    const navigate = useNavigate();
    const [context, setContext] = useContext(Context);

    const handleLogout = async (e) => {
        e.preventDefault();

        e.preventDefault();
        try {
            let user = await AuthAPI.logout();

            console.log(user);

            if (user) {
                setContext("");
                navigate("/users");
            } else {
                console.error("Error signing out.");
            }
        } catch (error) {
            console.error(error.message);
        }
    };
    return (
        <nav className="navbar navbar-expand-md navbar-dark fixed-top bg-dark">
            <div className="container-fluid">
                <a className="navbar-brand" href="#">
                    <h3>Cashbook</h3>
                </a>
                {!props?.hideLogout && (
                    <div className="d-flex">
                        <button
                            className="btn btn-light"
                            onClick={handleLogout}
                        >
                            Logout
                        </button>
                    </div>
                )}
            </div>
        </nav>
    );
};

export default Header;
