import React, { useState } from "react";
import userService from "../../services/users";
import { useLocation, useNavigate } from "react-router-dom";

const UserEdit = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const data = location.state;

    const [name, setName] = useState(data?.name);
    const [mob, setMob] = useState(data?.mob);
    const [email, setEmail] = useState(data?.email);
    const [address, setAddress] = useState(data?.address);
    const [tinySpinner, setTinySpinner] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();

        setTinySpinner(true);

        let userData = { name: name, mob: mob, email: email, address: address };
        await userService.updateItem(data.id, userData);

        setTinySpinner(false);

        navigate("/users");
    };

    return (
        <div>
            <h3>Edit User</h3>
            <div className="row justify-content-evenly mt-4">
                <div className="col-12">
                    <form
                        className="row g-3 needs-validation"
                        noValidate
                        onSubmit={handleSubmit}
                    >
                        <div className="col-md-4">
                            <label
                                htmlFor="validationCustom01"
                                className="form-label"
                            >
                                Name
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                id="validationCustom01"
                                defaultValue={name}
                                required
                                onChange={(e) => setName(e.target.value)}
                            />
                            <div className="valid-feedback">Looks good!</div>
                        </div>
                        <div className="col-md-4">
                            <label
                                htmlFor="validationCustom01"
                                className="form-label"
                            >
                                Phone
                            </label>
                            <input
                                type="tel"
                                className="form-control"
                                id="validationCustom01"
                                defaultValue={mob}
                                required
                                onChange={(e) => setMob(e.target.value)}
                            />
                            <div className="valid-feedback">Looks good!</div>
                        </div>
                        <div className="col-md-4">
                            <label
                                htmlFor="validationCustom01"
                                className="form-label"
                            >
                                Email
                            </label>
                            <input
                                type="email"
                                className="form-control"
                                id="validationCustom01"
                                defaultValue={email}
                                required
                                onChange={(e) => setEmail(e.target.value)}
                                disabled
                            />
                            <div className="valid-feedback">Looks good!</div>
                        </div>
                        <div className="col-md-12">
                            <label
                                htmlFor="validationCustom01"
                                className="form-label"
                            >
                                Address
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                id="validationCustom01"
                                defaultValue={address}
                                required
                                onChange={(e) => setAddress(e.target.value)}
                            />
                            <div className="valid-feedback">Looks good!</div>
                        </div>

                        <div className="col-12">
                            <button
                                className={`btn btn-primary ${
                                    tinySpinner && "disabled"
                                }`}
                                type="submit"
                            >
                                {tinySpinner && (
                                    <span
                                        class="spinner-border spinner-border-sm me-2"
                                        role="status"
                                        aria-hidden="true"
                                    ></span>
                                )}
                                Update
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default UserEdit;
