import { Link } from "react-router-dom";

const Aside = () => {
    return (
        <div className="d-flex flex-column flex-shrink-0 p-3 text-white bg-dark nav-height">
            <ul className="nav nav-pills flex-column mb-auto">
                <li>
                    <Link to="/users" className="nav-link text-white">
                        Users
                    </Link>
                </li>
            </ul>
        </div>
    );
};

export default Aside;
