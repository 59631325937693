import Aside from "../Aside";
import Footer from "../Footer";
import Header from "../Header";
import Base from "./Base";

const Backend = () => {
    return (
        <>
            <Header />
            <div className="container-fluid mt-70">
                <div className="row">
                    <div className="col-2 p-0">
                        <Aside />
                    </div>
                    <div className="col-10">
                        <div className="row m-3">
                            <Base />
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default Backend;
